import Icon from "../../components/Icon/Icon";
import jsPDF from "jspdf";
import "jspdf-autotable";

import html2canvas from "html2canvas";

export const toggleColumnVisibility = (metricKey, setHiddenColumns) => {
	setHiddenColumns((prev) => {
		const newHidden = new Set(prev);
		if (newHidden.has(metricKey)) {
			newHidden.delete(metricKey);
		} else {
			newHidden.add(metricKey);
		}
		return newHidden;
	});
};

export const toggleRowVisibility = (locationId, setHiddenRows) => {
	setHiddenRows((prev) => {
		const newHidden = new Set(prev);
		if (newHidden.has(locationId)) {
			newHidden.delete(locationId);
		} else {
			newHidden.add(locationId);
		}
		return newHidden;
	});
};

export const normalizeForDisplay = (value, digits = 2) => {
	if (!value) return "--";
	const normalized = Number(value).toLocaleString(undefined, {
		minimumFractionDigits: digits,
		maximumFractionDigits: digits,
	});
	return normalized;
};

export const getMetricValue = (location, metricKey) => {
	switch (metricKey) {
		case "building_sf":
			return location.building_sf ? Number(location.building_sf).toLocaleString() : "--";
		case "available_sf":
			return location.available_sf ? Number(location.available_sf).toLocaleString() : "--";
		case "location_quotient":
			return location.location_quotient ? `${location.location_quotient}%` : "--";
		case "avg_wage":
			return location.avg_wage ? `$${normalizeForDisplay(location.avg_wage)}` : "--";
		case "gross_price_sf":
			return location.gross_price_sf ? `$${normalizeForDisplay(location.gross_price_sf)}` : "--";
		case "electric_price_sf":
			return location.electric_price_sf ? `$${normalizeForDisplay(location.electric_price_sf)}` : "--";
		case "mit_wage":
			return location.mit_wage ? `${normalizeForDisplay(location.mit_wage)}` : "--";
		case "rent":
			return location.rent ? `$${location.rent}/NNN` : "--";
		case "year_built":
			return location.year_built ?? "--";
		case "location_suite":
			return location.location_suite ?? "--";
		case "percent_leased":
			return location.percent_leased ? `${normalizeForDisplay(location.percent_leased)}%` : "--";
		case "parking_ratio":
			return location.parking_ratio ?? "--";
		case "clear_height":
			return location.clear_height ? `${location.clear_height}` : "--";
		case "col_apt":
			return `$${location.col_apt}`;
		case "building_status":
			return location.status || "--";
		case "office_sf":
			return location.office_sf ?? "--";
		case "car_trailer_parking":
			return location.car_trailer_parking ?? "--";
		case "docs_grade_level_doors":
			return location.docs_grade_level_doors ?? "--";
		case "base_rent":
			return location.base_rent ? `${normalizeForDisplay(location.base_rent)}` : "--";
		case "loading":
			return location.loading ?? "--";
		case "opex":
			return location.opex ? `$${normalizeForDisplay(location.opex)}` : "--";
		case "job_count":
			return `${location.job_count.toLocaleString()}` ?? "--";
		case "leasing_company":
			return location.leasing_company || "--";
		// case "is_current":
		// 	return location.is_current ? "Yes" : "No";
		case "avg_employee_drive_time":
			return location.employee_proposed_location_drivetime_average
				? `${Math.ceil(location.employee_proposed_location_drivetime_average / 60)} min`
				: "--";
		case "avg_customer_drive_time":
			return location.customer_proposed_location_drivetime_average
				? `${Math.ceil(location.customer_proposed_location_drivetime_average / 60)} min`
				: "--";
		case "notes":
			return location.notes || "--";
		default:
			return "--";
	}
};

export const getVisibleLocations = (locations, hiddenRows) => {
	return locations.filter((location) => !hiddenRows.has(location._id));
};

export const renderLocationInfo = (location, photos) => (
	<div className='location-cell'>
		<div className={`${photos[location._id] ? "comparison-photo mt-0" : "comparison-photo-placeholder mt-0"}`}>
			{photos[location._id] ? (
				<img src={photos[location._id]} alt={location.location_name} className='location-image' />
			) : (
				<img src='/assets/images/building.png' alt={location.location_name} className='location-image' />
			)}
		</div>
	</div>
);

export const generatePDFWithImages = async (setIsGenerating, contextData) => {
	setIsGenerating(true);
	try {
		const pdf = new jsPDF({
			orientation: "landscape",
			unit: "px",
			format: "letter",
		});

		const getBase64Image = async (imgUrl) => {
			let errorLog = JSON.parse(localStorage.getItem("pdfGenerationErrors") || "[]");

			try {
				const controller = new AbortController();
				const timeoutId = setTimeout(() => controller.abort(), 5000);

				const response = await fetch(imgUrl, {
					signal: controller.signal,
					mode: "cors",
					cache: "no-cache",
				});

				clearTimeout(timeoutId);

				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}

				const blob = await response.blob();

				return new Promise((resolve, reject) => {
					const reader = new FileReader();

					reader.onloadend = () => {
						errorLog.push({
							timestamp: new Date().toISOString(),
							type: "success",
							url: imgUrl,
							result: "Image converted successfully",
						});
						localStorage.setItem("pdfGenerationErrors", JSON.stringify(errorLog));
						resolve(reader.result);
					};

					reader.onerror = (error) => {
						errorLog.push({
							timestamp: new Date().toISOString(),
							type: "fileReaderError",
							url: imgUrl,
							error: error.toString(),
							stack: error.stack,
						});
						localStorage.setItem("pdfGenerationErrors", JSON.stringify(errorLog));
						reject(error);
					};

					reader.readAsDataURL(blob);
				});
			} catch (error) {
				errorLog.push({
					timestamp: new Date().toISOString(),
					type: "fetchError",
					url: imgUrl,
					error: error.toString(),
					stack: error.stack,
					message: error.message,
					name: error.name,

					code: error.code,
					cause: error.cause?.toString(),
					aborted: error instanceof DOMException && error.name === "AbortError",
				});

				localStorage.setItem("pdfGenerationErrors", JSON.stringify(errorLog));

				return null;
			}
		};
		const printPages = document.querySelectorAll(".print-preview-wrapper .print-page");
		const printMapPages = document.querySelectorAll(".map-print.print-preview-wrapper .print-map-page");

		const margin = 10;
		const pageWidth = pdf.internal.pageSize.width;
		const pageHeight = pdf.internal.pageSize.height;

		const addHeader = (pageIndex) => {
			// Logo remains the same
			pdf.addImage("/assets/images/bluechip-logo-black.png", "png", margin, margin, 40, 8);

			pdf.setFont("helvetica", "bold");

			// Main title
			pdf.setFontSize(12);
			pdf.text(
				`${contextData.dashboard_property_type} -- created from Business Insights`,
				pdf.internal.pageSize.width / 2,
				15,
				{
					align: "center",
				},
			);

			// Subtitle
			pdf.setFontSize(10);
			pdf.text(
				`Market Survey Comparison (${contextData.dashboard_property_type})`,
				pdf.internal.pageSize.width / 2,
				25,
				{ align: "center" },
			);

			// SOC info
			pdf.setFontSize(8);
			pdf.text(`SOC: ${contextData?.socData?.soc_name ?? ""}`, pdf.internal.pageSize.width - margin, 20, {
				align: "right",
			});
		};

		for (let pageIndex = 0; pageIndex < printPages.length; pageIndex++) {
			if (pageIndex > 0) {
				pdf.addPage();
			}

			addHeader(pageIndex);
			const page = printPages[pageIndex];

			const headers = Array.from(page.querySelectorAll("table thead th")).map((th) => {
				const spanContent = th.querySelector("span")?.innerHTML || th.innerHTML;

				const textWithoutHtml = spanContent.replace(/<[^>]+>/g, "");

				return textWithoutHtml
					.trim()
					.split(/[\s/]+/)
					.join("\n");
			});

			const rows = Array.from(page.querySelectorAll("table tbody tr")).map((tr) =>
				Array.from(tr.querySelectorAll("td")).map((td) => ({
					text: td.textContent.trim(),
					photoUrl: td.querySelector("img")?.src,
				})),
			);

			const processedRows = await Promise.all(
				rows.map(async (row) => {
					return Promise.all(
						row.map(async (cell) => {
							if (cell.photoUrl) {
								const base64Image = await getBase64Image(cell.photoUrl);
								return {
									content: "",
									styles: { halign: "center", valign: "middle" },
									imageUrl: base64Image,
								};
							}
							return cell.text || "";
						}),
					);
				}),
			);

			const headersUpperCase = headers.map((header) => header.toUpperCase());

			const calculateColumnWidths = (headers = [], rows = []) => {
				const columnWidths = {};

				// Fixed widths for first three columns
				// Number column
				columnWidths[0] = { cellWidth: 12 };
				// Photo column
				columnWidths[1] = { cellWidth: 40 };
				// Location name
				columnWidths[2] = { cellWidth: 40 };

				const leasingCompanyIndex = headers.findIndex(
					(header) => header.toLowerCase().includes("landlord") || header.toLowerCase().includes("developer"),
				);
				if (leasingCompanyIndex !== -1) {
					columnWidths[leasingCompanyIndex] = { cellWidth: 35 };
				}

				// Calculate remaining column widths
				headers.forEach((header, index) => {
					if (columnWidths[index]) return;

					const headerLength = header.length * 5;
					const maxContentLength = rows.reduce((maxLen, row) => {
						const cellContent = row[index] || "";
						return Math.max(maxLen, String(cellContent).length * 4);
					}, 0);

					columnWidths[index] = {
						cellWidth: Math.max(headerLength, maxContentLength, 20),
					};
				});

				return columnWidths;
			};
			const columnStyles = calculateColumnWidths();

			pdf.autoTable({
				head: [headersUpperCase],
				body: processedRows,
				startY: 35,
				theme: "grid",
				styles: {
					font: "helvetica",
					fontWeight: "700",
					fontSize: 6,
					halign: "center",
					valign: "middle",
					lineWidth: 0.1,
					lineColor: [238, 238, 238],
				},
				headStyles: {
					fillColor: [245, 245, 245],
					textColor: [0, 0, 0],
					fontStyle: "bold",
					lineWidth: 0.2,
					lineColor: [238, 238, 238],
				},
				alternateRowStyles: {
					fillColor: [250, 250, 250],
				},
				margin: { left: margin, right: margin },
				tableWidth: pdf.internal.pageSize.width - 2 * margin,
				columnStyles: columnStyles,
				didDrawCell: (data) => {
					const cell = data.cell.raw;
					if (cell && cell.imageUrl) {
						try {
							const { x, y, width, height } = data.cell;
							const padding = 2;
							pdf.addImage(
								cell.imageUrl,
								"JPEG",
								x + padding,
								y + padding,
								width - padding * 2,
								height - padding * 2,
							);
						} catch (e) {
							console.error("Failed to add image to PDF:", e);
						}
					}
				},
			});
		}

		// Process map pages from DOM
		for (let i = 0; i < printMapPages.length; i++) {
			if (i > 0 || pdf.internal.getNumberOfPages() > 0) {
				pdf.addPage();
			}

			addHeader(pdf.internal.getNumberOfPages() - 1);

			const mapPage = printMapPages[i];
			const canvas = await html2canvas(mapPage, {
				scale: 3,
				useCORS: true,
				logging: false,
				backgroundColor: "#ffffff",
			});

			const canvasWidth = canvas.width;
			const canvasHeight = canvas.height;
			const availableWidth = pageWidth - 2 * margin;
			const availableHeight = pageHeight - (2 * margin + 20);
			const scaleX = availableWidth / canvasWidth;
			const scaleY = availableHeight / canvasHeight;
			const scale = Math.min(scaleX, scaleY);
			const scaledWidth = canvasWidth * scale;
			const scaledHeight = canvasHeight * scale;

			const x = margin;
			const y = margin + 25;

			pdf.addImage(canvas.toDataURL("image/jpeg", 1.0), "JPEG", x, y, scaledWidth, scaledHeight);
		}

		const filename = `${contextData.dashboard_title || "property-comparison"}.pdf`;
		pdf.save(filename);
	} catch (error) {
		console.error("Error generating PDF:", error);
	} finally {
		setIsGenerating(false);
	}
};

export const getMetrics = (isIndustrial) => {
	const baseMetrics = [
		{ building_status: "Building Status", default: true, editable: false },
		{ leasing_company: `Leasing Company`, default: true, editable: true },
		// { is_current: `Current Location`, default: true, editable: false },
		{ building_sf: "Building SF", default: true, editable: true },
		{ available_sf: "Available SF", default: true, editable: true },
		{ office_sf: "Office SF", default: true, type: "industrial", editable: true },
		{ clear_height: "Clear Height", default: true, type: "industrial", editable: true },
		{ car_trailer_parking: "Car/Trailer Parking", default: true, type: "industrial", editable: true },
		{ docs_grade_level_doors: "Docks/Grade Level Doors", default: true, type: "industrial", editable: true },
		{ year_built: "Year Built", default: true, type: "office", editable: true },
		{ parking_ratio: "Parking Ratio", default: true, type: "office", editable: true },
		{ location_suite: "Suite", default: true, type: "office", editable: true },
		{ percent_leased: "Percent Leased", default: true, type: "office", editable: true },
		{ base_rent: "Base Rent", default: true, editable: true },
		{ opex: "OPEX", default: true, editable: true },
		{ electric_price_sf: "Electric Price SF", default: true, editable: true, type: "office" },
		{ gross_price_sf: "Gross PSF", default: true, editable: true },
		{ job_count: "# Jobs (15min)", default: true },
		{ avg_wage: "AVG Wage (Hrly)", default: true },
		{ mit_wage: "MIT Living Wage", default: true },
		{ col_apt: "Cost Of Living (Apt)", default: true },
		{ location_quotient: "LQ", default: true },

		{ notes: "Notes", default: true, editable: false },
	];

	// Filter metrics based on property type
	return baseMetrics.filter((metric) => {
		if (!metric.type) return true;

		if (isIndustrial && metric.type === "industrial") return true;
		if (!isIndustrial && metric.type === "office") return true;

		return false;
	});
};
