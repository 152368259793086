import React from "react";
import { useState } from "react";
import InputToggle from "../../InputToggle/InputToggle";
function DashboardTitle({
	error,
	errorMessage,
	handleOnDashboardTitleChange,
	dashboardTitle,
	officeLocationType,
	setLocationType,
}) {
	return (
		<div style={{ width: "100%", marginBottom: "28px" }}>
			<div style={{ marginBottom: 0, position: "relative" }} className='card-title'>
				<label
					style={{
						left: "0",
						marginRight: 0,
						top: "0",
						marginBottom: "8px",
						position: "relative",
						fontSize: "24px",
					}}
					htmlFor='dashboardTitle'
				>
					Dashboard Title
				</label>
				{error && (
					<span className='sub-text' style={{ color: "red" }}>
						*{errorMessage}
					</span>
				)}
			</div>

			<div style={{ gap: "10px" }} className='pointer d-flex align-items-center'>
				<p
					onClick={() => {
						setLocationType(!officeLocationType);
					}}
				>
					Office
				</p>
				<InputToggle
					className='toggle-radius'
					trueColor={"#5c86c1"}
					falseColor={"#5c86c1"}
					label={""}
					name='hideCustomerLocations'
					isChecked={officeLocationType}
					onValueChange={(e) => {
						setLocationType(!officeLocationType);
					}}
				/>
				<p
					onClick={() => {
						setLocationType(!officeLocationType);
					}}
				>
					Industrial
				</p>
			</div>
			<div className='input-item'>
				<input
					id='dashboardTitle'
					value={dashboardTitle}
					type='text'
					name='dashboard_title'
					required={true}
					onChange={handleOnDashboardTitleChange}
				/>
			</div>
		</div>
	);
}

export default DashboardTitle;
