import React, { useState, useEffect } from "react";
import { Marker, Popup } from "react-map-gl";

const CustomMarker = ({
	lat,
	index = false,
	lng,
	name,
	image,
	onClick,
	showPopup = false,
	popupContent,
	className = "",
	offset = [0, -15],
}) => {
	const [isPopupOpen, setIsPopupOpen] = useState(showPopup);

	const handleClick = () => {
		setIsPopupOpen(!isPopupOpen);
		if (onClick) onClick();
	};
	//
	return (
		<>
			<Marker latitude={lat} longitude={lng} anchor='bottom'>
				<div className={`map-marker ${className}`} onClick={handleClick}>
					{image && (
						<div className='marker-image'>
							<img
								src={image}
								alt={name}
								style={{ width: "100%", height: "100%", borderRadius: "50%" }}
							/>
						</div>
					)}

					<div className='marker-name marker-index'>
						{name} {index !== false && index + 1}
					</div>
				</div>
			</Marker>

			{isPopupOpen && popupContent && (
				<Popup
					latitude={lat}
					longitude={lng}
					anchor='bottom'
					offset={offset}
					onClose={() => setIsPopupOpen(false)}
					closeOnClick={false}
				>
					{popupContent}
				</Popup>
			)}
		</>
	);
};

export default CustomMarker;
