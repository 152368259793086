import React from "react";
import InputText from "../../components/InputText/InputText";
import { useComparison } from "./ComparisonContext";

const formatNumericValue = (value) => {
	if (!isNaN(value) && !/[^0-9.]/.test(value)) {
		const num = Number(value);

		if (value.includes(".")) {
			return num.toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
		}

		return num.toLocaleString();
	}

	return value;
};

const EditableCell = ({ location, metricKey, currentValue, isEditable, commas = true }) => {
	const { metricEdits, updateMetricValue } = useComparison();

	if (!isEditable) {
		return <div dangerouslySetInnerHTML={{ __html: currentValue }} />;
	}

	const editedValue = metricEdits[location._id]?.[metricKey] ?? currentValue.toString().replace(/,/g, "");

	const displayValue = commas ? formatNumericValue(editedValue) : editedValue;

	return (
		<InputText
			value={displayValue}
			onValueChange={(newValue) => updateMetricValue(location._id, metricKey, newValue)}
			size='sm'
			theme='gray-outline'
		/>
	);
};

export default EditableCell;
