import React from "react";
import Accordion from "../../Accordion/Accordion";
import { checkValueIsPositiveDecimal } from "../../../utils/constants";
import { addCommas } from "../../../helper/number.helpers";
function IndustrialFields({ handleBlur, handleFocus, index, locationDescription, handleOnDiscriptionChange }) {
	return (
		<Accordion collapsedTitle='Show all industrial fields' expandedTitle={"Hide all industrial fields"}>
			<div className='input-item'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.building_sf ? " hasFocus" : "")
					}
					type='text'
					name='building_sf'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.building_sf
							? addCommas(locationDescription[index]?.building_sf)
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange("building_sf", checkValueIsPositiveDecimal(e?.target?.value), index);
					}}
				/>
				<label className=''>Building SF</label>
			</div>
			<div className='input-item'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.available_sf ? " hasFocus" : "")
					}
					type='text'
					name='available_sf'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.available_sf
							? addCommas(locationDescription[index]?.available_sf)
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange("available_sf", checkValueIsPositiveDecimal(e?.target?.value), index);
					}}
				/>
				<label className=''>Available SF</label>
			</div>
			<div className='input-item'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.office_sf ? " hasFocus" : "")
					}
					type='text'
					name='office_sf'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.office_sf
							? addCommas(locationDescription[index]?.office_sf)
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange("office_sf", checkValueIsPositiveDecimal(e?.target?.value), index);
					}}
				/>
				<label className=''>Office SF</label>
			</div>
			<div className='input-item'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.clear_height ? " hasFocus" : "")
					}
					type='text'
					name='clear_height'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.clear_height
							? locationDescription[index]?.clear_height
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange("clear_height", checkValueIsPositiveDecimal(e?.target?.value), index);
					}}
				/>
				<label className=''>Clear Height</label>
			</div>
			<div className='input-item'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.car_trailer_parking
							? " hasFocus"
							: "")
					}
					type='text'
					name='car_trailer_parking'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.car_trailer_parking
							? locationDescription[index]?.car_trailer_parking
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange(
							"car_trailer_parking",
							checkValueIsPositiveDecimal(e?.target?.value),
							index,
						);
					}}
				/>
				<label className=''>Car/Trailer Parking</label>
			</div>
			<div className='input-item'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.docs_grade_level_doors
							? " hasFocus"
							: "")
					}
					type='text'
					name='docs_grade_level_doors'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.docs_grade_level_doors
							? locationDescription[index]?.docs_grade_level_doors
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange(
							"docs_grade_level_doors",
							checkValueIsPositiveDecimal(e?.target?.value),
							index,
						);
					}}
				/>
				<label className=''>Docs/Grade Level Doors</label>
			</div>
			<div className='input-item position-relative'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.base_rent ? " hasFocus" : "")
					}
					type='text'
					name='base_rent'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.base_rent
							? locationDescription[index]?.base_rent
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange("base_rent", checkValueIsPositiveDecimal(e?.target?.value), index);
					}}
				/>
				<label className=''>Base Rent</label>
			</div>
		</Accordion>
	);
}

export default IndustrialFields;
