import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { getPhotos } from "../../services/dashboards/townson-dashboard.services/townson-dashboard-data";
import "./CompareLocationsExcel.scss";
import Icon from "../../components/Icon/Icon";
import { useComparison } from "./ComparisonContext";
import ComparisonViewMap from "./ComparisonViewMap";
import {
	toggleColumnVisibility,
	toggleRowVisibility,
	getMetricValue,
	getVisibleLocations,
	renderLocationInfo,
	generatePDFWithImages,
	getMetrics,
} from "./ComparisonViewExcel.helpers";
import ComparisonViewSidebar from "./ComparisonViewSidebar";
import LoadingOverlay from "../../../src/components/loader/loader.component";
import EditableCell from "./ComparisonViewEditableCell";
function ComparisonViewExcel() {
	// Throw an error if we got here with out any localStorage
	if (localStorage.getItem("comparisonData") === null) {
		return (
			<p className='text-center'>
				The property comparison is not available. Please close this window and try again.
			</p>
		);
	}

	const {
		comparisonData: contextData,
		setComparisonData,
		metricEdits,
		saveMetricEdits,
		cancelMetricEdits,
	} = useComparison();
	const [photos, setPhotos] = useState({});
	const [error, setError] = useState(null);
	const [hiddenRows, setHiddenRows] = useState(new Set());
	const [hiddenColumns, setHiddenColumns] = useState(new Set());
	const [toggle, setToggle] = useState(false);
	const [isGenerating, setIsGenerating] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [mapLoaded, setMapLoaded] = useState(false);
	const [photosLoaded, setPhotosLoaded] = useState(false);
	const [dataLoaded, setDataLoaded] = useState(false);

	const [orderedMetrics, setOrderedMetrics] = useState([]);
	const [orderedLocations, setOrderedLocations] = useState([]);

	// Clear out old data after we close the window
	useEffect(() => {
		const handleUnload = () => {
			localStorage.removeItem("comparisonData");
			setComparisonData(null);
		};

		window.addEventListener("beforeunload", handleUnload);

		return () => {
			window.removeEventListener("beforeunload", handleUnload);
			handleUnload();
		};
	}, [setComparisonData]);

	useEffect(() => {
		if (contextData?.dashboard_property_type) {
			const filteredMetrics = getMetrics(contextData.dashboard_property_type === "Industrial");

			const userSelectedMetrics = filteredMetrics.filter((metric) => {
				const metricKey = Object.keys(metric)[0];
				const metricName = metric[metricKey];
				return contextData.selectedMetrics?.[metricName];
			});

			const initialMetrics = [
				...userSelectedMetrics,

				contextData.selectedMetrics?.["Avg Employee Drive Time"] && {
					avg_employee_drive_time: "Avg Employee Drive Time",
				},
				contextData.selectedMetrics?.["Avg Customer Drive Time"] && {
					avg_customer_drive_time: "Avg Customer Drive Time",
				},
			].filter(Boolean);

			setOrderedMetrics(initialMetrics);
		}
	}, [contextData?.dashboard_property_type, contextData?.selectedMetrics]);

	useEffect(() => {
		const checkData = () => {
			const data = localStorage.getItem("comparisonData");
			if (data && data !== "") {
				try {
					const parsedData = JSON.parse(data);
					if (parsedData?.locations?.length > 0) {
						setDataLoaded(true);

						if (!contextData?.locations?.length) {
							setComparisonData(parsedData);
						}
					}
				} catch (e) {
					console.error("Error parsing comparison data:", e);
				}
			}
		};

		checkData();
		const interval = setInterval(checkData, 500);
		return () => clearInterval(interval);
	}, [contextData]);

	useEffect(() => {
		async function fetchPhotos() {
			if (!contextData?.locations?.length) {
				setPhotosLoaded(true);
				return;
			}

			try {
				const locationIds = contextData.locations.map((location) => location._id);
				const payload = { primary: true, ids: locationIds };
				const response = await getPhotos(payload);

				const photoMap = {};
				response?.data.forEach((photo) => {
					photoMap[photo.location_id] = photo.url;
				});
				setPhotos(photoMap);
			} catch (err) {
				console.error("Error fetching photos:", err);
				setError("Error loading location photos");
			} finally {
				setPhotosLoaded(true);
			}
		}

		setPhotosLoaded(false);
		fetchPhotos();
	}, [contextData]);

	useEffect(() => {
		if (contextData?.locations) {
			setOrderedLocations(contextData.locations);
		}
	}, [contextData]);

	useEffect(() => {
		if (!contextData?.locations?.length) {
			setMapLoaded(true);
		}
	}, [contextData]);

	useEffect(() => {
		setIsLoading(!photosLoaded || !mapLoaded || !dataLoaded);
	}, [photosLoaded, mapLoaded, dataLoaded]);

	if (error) {
		return (
			<div className='error-container'>
				<h2>{error}</h2>
			</div>
		);
	}

	if (isLoading) {
		return <LoadingOverlay text={"Loading comparison data..."} />;
	}

	const visibleLocations = getVisibleLocations(orderedLocations, hiddenRows);
	const visibleMetrics = orderedMetrics.filter((metric) => !hiddenColumns.has(Object.keys(metric)[0]));

	const renderTable = (className) => (
		<div className={`table-container ${className}`}>
			<h4>{contextData?.dashboard_title ?? ""}</h4>
			<div className='d-flex justify-content-between align-items-center' style={{ padding: "16px 0" }}>
				<img className='logo' src='/assets/images/bluechip-logo-black.svg' alt='' />

				<h6 className=''>Market Survey Comparison ({contextData?.dashboard_property_type})</h6>
				<h6 style={{ fontSize: "16px" }} className=''>
					{" "}
					SOC: {contextData?.socData?.soc_name}
				</h6>
			</div>
			<div className='table-wrapper'>
				<table>
					<thead>
						<tr className='position-relative'>
							<th className='location-header location-count text-center' style={{ width: "50px" }}>
								#
							</th>
							<th className='location-header location_photo'>Photo</th>
							<th className='location-header'>Location</th>
							{visibleMetrics.map((metric, index) => {
								const metricKey = Object.keys(metric)[0];
								const metricName = metric[metricKey];
								return (
									<th key={index} className={`${metricKey} metric-header position-relative`}>
										<div className='align-items-center'>
											<span className='ml-2'>
												{metricKey === "leasing_company" ? (
													<>
														{metricName.split("/").map((part, i) =>
															i === 0 ? (
																part
															) : (
																<>
																	<br />
																	{part}
																</>
															),
														)}
													</>
												) : (
													<>{metricName}</>
												)}
											</span>
										</div>
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody>
						{visibleLocations.map((location, locationIndex) => (
							<tr key={location._id} className={`${locationIndex % 2 === 0 ? "even" : "odd"}`}>
								<td className='text-center position-relative number-cell'>
									<span>{locationIndex + 1}</span>
								</td>
								<td className='location-column'>{renderLocationInfo(location, photos)}</td>
								<td className='location-name-cell'>
									<span className='location-name'>{location.location_name}</span>
								</td>
								{visibleMetrics.map((metric, metricIndex) => {
									const metricKey = Object.keys(metric)[0];
									const isEditable = metric.editable;
									return (
										<td key={metricIndex} className={`metric-value ${metricKey}`}>
											<EditableCell
												location={location}
												metricKey={metricKey}
												currentValue={getMetricValue(location, metricKey)}
												isEditable={isEditable}
												commas={metricKey !== "year_built"}
											/>
										</td>
									);
								})}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);

	const renderPagedTables = () => {
		const locationsPerPage = 10;
		const pages = [];
		let globalIndex = 0;

		for (let i = 0; i < visibleLocations.length; i += locationsPerPage) {
			const pageLocations = visibleLocations.slice(i, i + locationsPerPage);
			pages.push(
				<div key={i} className='print-page d-none'>
					<div style={{ padding: "16px 0" }} className='justify-content-between d-flex'>
						<img className='logo' src='/assets/images/bluechip-logo-black.svg' alt='' />
						<h5 className='ml-auto mr-0'>{contextData?.dashboard_property_type} Property Comparison</h5>
					</div>
					<div className='table-container'>
						<div className='table-wrapper'>
							<table>
								<thead>
									<tr className='position-relative'>
										<th
											className='location-header text-center location-count'
											style={{ width: "50px" }}
										>
											#
										</th>
										<th className='location-header location-column location_photo'>Photo</th>
										<th className='location-header location-name-cell'>Location</th>
										{visibleMetrics.map((metric, index) => {
											const metricKey = Object.keys(metric)[0];
											const metricName = metric[metricKey];
											return (
												<th
													key={index}
													className={`${metricKey} metric-header position-relative`}
												>
													<div className='align-items-center'>
														<span className='ml-2'>
															{metricKey === "leasing_company" ? (
																<>
																	{metricName.split("/").map((part, i) =>
																		i === 0 ? (
																			part
																		) : (
																			<>
																				<br />
																				{part}
																			</>
																		),
																	)}
																</>
															) : (
																metricName
															)}
														</span>
													</div>
												</th>
											);
										})}
									</tr>
								</thead>
								<tbody>
									{pageLocations.map((location) => (
										<tr
											key={location._id}
											className={`${globalIndex++ % 2 === 0 ? "even" : "odd"}`}
										>
											<td className='text-center position-relative location-count number-cell'>
												<span>{globalIndex}</span>
											</td>
											<td className='location-column location_photo'>
												{renderLocationInfo(location, photos)}
											</td>
											<td className='location-name-cell'>
												<span className='location-name'>{location.location_name}</span>
											</td>
											{visibleMetrics.map((metric, metricIndex) => {
												const metricKey = Object.keys(metric)[0];
												return (
													<td
														key={metricIndex}
														className={`metric-value ${metricKey}`}
														dangerouslySetInnerHTML={{
															__html: getMetricValue(location, metricKey),
														}}
													/>
												);
											})}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>,
			);
		}
		return pages;
	};

	if (localStorage.getItem("comparisonData") === null) {
		return <p>The property comparison is not available. Please close this window and try again.</p>;
	}

	return (
		<>
			{isLoading || !dataLoaded || !contextData?.locations?.length ? (
				<LoadingOverlay text={"Loading survey data..."} />
			) : (
				<>
					{isGenerating && <LoadingOverlay text={"Your PDF is generating. Please wait."} />}
					<div className='location-comparisons'>
						<div className=''>
							<div className='export justify-content-center' style={{ gap: "10px", display: "flex" }}>
								{Object.keys(metricEdits).length === 0 && (
									<button
										onClick={() => {
											setIsGenerating(true);
											setTimeout(() => {
												generatePDFWithImages(setIsGenerating, contextData);
											}, 2000);
										}}
										className='btn theme-primary size-md width-standard btn-round'
										disabled={isGenerating}
									>
										{isGenerating ? "Generating..." : "Generate PDF"}
									</button>
								)}
								{Object.keys(metricEdits).length > 0 && (
									<>
										<button onClick={cancelMetricEdits} className='button btn outline secondary'>
											Cancel Changes
										</button>
										<button
											onClick={saveMetricEdits}
											className='btn theme-primary size-md width-standard btn-round'
										>
											Save Changes
										</button>
									</>
								)}
							</div>
							<div onClick={() => setToggle(!toggle)} className='openToggle'>
								<Icon className='placeholder-icon' icon='gear-line' size='lg' />
							</div>
							<ComparisonViewSidebar
								setIsLoading={setIsLoading}
								metrics={orderedMetrics}
								toggle={toggle}
								setToggle={setToggle}
								setHiddenColumns={setHiddenColumns}
								setHiddenRows={setHiddenRows}
								contextData={{ ...contextData, locations: orderedLocations }}
								toggleColumnVisibility={toggleColumnVisibility}
								toggleRowVisibility={toggleRowVisibility}
								hiddenColumns={hiddenColumns}
								hiddenRows={hiddenRows}
								setMetricsOrder={setOrderedMetrics}
								setLocationsOrder={setOrderedLocations}
							/>
							<div className='print-page' onClick={() => setToggle(false)}>
								{renderTable("web-version")}
							</div>
							<div className='print-preview-wrapper'>{renderPagedTables()}</div>
						</div>
					</div>
					<div onClick={() => setToggle(false)}>
						<ComparisonViewMap
							isGenerating={isGenerating}
							visibleLocations={visibleLocations}
							onMapLoad={() => setMapLoaded(true)}
						/>
					</div>
				</>
			)}
		</>
	);
}

export default ComparisonViewExcel;
