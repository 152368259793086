import React from "react";
import Accordion from "../../Accordion/Accordion";
import { checkValueIsPositiveDecimal } from "../../../utils/constants";
import { addCommas, convertToPercent } from "../../../helper/number.helpers";
function OfficeFields({ handleBlur, handleFocus, index, locationDescription, handleOnDiscriptionChange }) {
	return (
		<Accordion collapsedTitle='Show all office fields' expandedTitle={"Hide all office fields"}>
			<div className='input-item'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.year_built ? " hasFocus" : "")
					}
					type='text'
					name='year_built'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.year_built
							? locationDescription[index]?.year_built
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange("year_built", checkValueIsPositiveDecimal(e?.target?.value), index);
					}}
				/>
				<label className=''>Year built</label>
			</div>
			<div className='input-item'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.building_sf ? " hasFocus" : "")
					}
					type='text'
					name='building_sf'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.building_sf
							? addCommas(locationDescription[index]?.building_sf)
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange("building_sf", checkValueIsPositiveDecimal(e?.target?.value), index);
					}}
				/>
				<label className=''>Building SF</label>
			</div>
			<div className='input-item'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.parking_ratio ? " hasFocus" : "")
					}
					type='text'
					name='parking_ratio'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.parking_ratio
							? locationDescription[index]?.parking_ratio
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange(
							"parking_ratio",
							checkValueIsPositiveDecimal(e?.target?.value),
							index,
						);
					}}
				/>
				<label className=''>Parking Ratio</label>
			</div>
			<div className='input-item'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.available_sf ? " hasFocus" : "")
					}
					type='text'
					name='available_sf'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.available_sf
							? addCommas(locationDescription[index]?.available_sf)
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange("available_sf", checkValueIsPositiveDecimal(e?.target?.value), index);
					}}
				/>
				<label className=''>Available SF</label>
			</div>
			<div className='input-item'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.location_suite ? " hasFocus" : "")
					}
					type='text'
					name='location_suite'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.location_suite
							? locationDescription[index]?.location_suite
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange("location_suite", e?.target?.value, index);
					}}
				/>
				<label className=''>Location Suite</label>
			</div>
			<div className='input-item'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.percent_leased ? " hasFocus" : "")
					}
					type='text'
					name='percent_leased'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.percent_leased
							? convertToPercent(locationDescription[index]?.percent_leased)
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange(
							"percent_leased",
							checkValueIsPositiveDecimal(e?.target?.value),
							index,
						);
					}}
				/>
				<label className=''>Percent Leased</label>
			</div>
			<div className='input-item'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.base_rent ? " hasFocus" : "")
					}
					type='text'
					name='base_rent'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.base_rent
							? locationDescription[index]?.base_rent
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange("base_rent", checkValueIsPositiveDecimal(e?.target?.value), index);
					}}
				/>
				<label className=''>Base Rent</label>
			</div>
			<div className='input-item'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.electric_price_sf ? " hasFocus" : "")
					}
					type='text'
					name='electric_price_sf'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.electric_price_sf
							? locationDescription[index]?.electric_price_sf
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange(
							"electric_price_sf",
							checkValueIsPositiveDecimal(e?.target?.value),
							index,
						);
					}}
				/>
				<label className=''>Electric Price SF</label>
			</div>
			<div className='input-item'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.gross_price_sf ? " hasFocus" : "")
					}
					type='text'
					name='gross_price_sf'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.gross_price_sf
							? locationDescription[index]?.gross_price_sf
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange(
							"gross_price_sf",
							checkValueIsPositiveDecimal(e?.target?.value),
							index,
						);
					}}
				/>
				<label className=''>Gross SF</label>
			</div>
		</Accordion>
	);
}

export default OfficeFields;
