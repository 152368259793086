import React, { useState, useEffect } from "react";

import "../../../pages/townson-dashboard-list/townson-dashabord-list.scss";
import Icon from "../../Icon/Icon";
import PdfImages from "../../../pages/townson-dashboard-list/components/client-data-upload-component/PdfImages/PdfImages";
import UploadPDFsToLocations from "../../../pages/townson-dashboard-list/components/client-data-upload-component/upload-pdfs-to-locations";
import OfficeFields from "./OfficeFields";
import GeneralLocationFields from "./GeneralLocationFields";
import IndustrialFields from "./IndustrialFields";
import ExcelTemplate from "./ExcelTemplate";
function AddEditLocations({
	dynamicArray,
	proposedLocationIndex,
	setLocation,
	locationDescription,
	setLocationDescription,
	setProposedLocationIndex,
	handleFocus = () => {},
	handleBlur = () => {},
	maxLengthTextArea,
	maxAllowed,
	handleOnChangeFile,
	csvError,
	location = { currentLocation: {}, proposedLocations: [{}] },
	error,
	errorMessage,
	handleLocationPDFFiles,
	proposedLocations,
	pdfFileName,
	progressFileUpload,
	errorFileUpload,
	removePDFFile,
	cancelPDFUpload,
	locationPDFFilesUpload,
	screenLocation = "score-insights",
	dashboardData,
	officeLocationType,
	photos,
	setPhotos,
}) {
	const [errorLocationValidation, setErrorLocationValidation] = useState([]);

	useEffect(() => {
		if (screenLocation === "business-insights") {
			if (dashboardData?.proposedLocations?.length) {
				// Map locations for position data when we have Redux data
				const mappedLocations = dashboardData.proposedLocations.map((location) => ({
					address: { freeformAddress: location.location_name },
					position: {
						is_current: location.is_current,
						lat: location.latitude,
						lon: location.longitude,
					},
					_id: location._id,
					id: location._id,
					location_id: location?._id,
					pdfs: (location?.pdfs || []).map((pdf) => ({
						...pdf,
						location_id: location._id,
					})),
				}));

				const existingPdfs = dashboardData.proposedLocations
					.map((location, index) => {
						if (location?.pdfs?.length) {
							return {
								location_index: index,
								location_id: location?._id,
								files: location.pdfs.map((pdf) => ({
									location_id: location?._id,
									name: pdf.name,
									url: pdf.url,
								})),
							};
						}
						return null;
					})
					.filter(Boolean);

				const mappedDescriptions = dashboardData.proposedLocations.map((location) => ({
					is_current: location.is_current,
					opex_value: location.opex_value,
					status: location.status,
					notes: location.notes,
					base_rent: location.base_rent,
					building_sf: location.building_sf,
					car_trailer_parking: location.car_trailer_parking,
					clear_height: location.clear_height,
					docs_grade_level_doors: location.docs_grade_level_doors,
					electric_price_sf: location.electric_price_sf,
					leasing_company: location.leasing_company,
					gross_price_sf: location.gross_price_sf,
					office_sf: location.office_sf,
					parking_ratio: location.parking_ratio,
					percent_leased: location.percent_leased,
					available_sf: location.available_sf,
					year_built: location.year_built,
					location_suite: location.location_suite,
				}));

				setLocation((prevLocation) => ({
					...prevLocation,
					proposedLocations: mappedLocations,
				}));

				setLocationDescription(mappedDescriptions);

				if (existingPdfs.length > 0) {
					handleLocationPDFFiles({
						type: "existing",
						pdfs: existingPdfs,
					});
				}
			} else {
				setLocation((prevLocation) => ({
					...prevLocation,
					proposedLocations: [
						{
							address: { freeformAddress: "" },
							position: { is_current: false, lat: null, lon: null },
						},
					],
				}));

				setLocationDescription([
					{
						is_current: false,
						note: "",
						status: "",
						gross_price_sf: "",
						opex_value: "",
						leasing_company: "",
					},
				]);
			}
		} else {
			if (dashboardData && dashboardData?.locations) {
				const mappedLocations = dashboardData.locations.map((location) => ({
					address: { freeformAddress: location.address },
					position: {
						is_current: location.is_current,
						lat: location.lat,
						lon: location.lng,
					},
				}));

				const mappedDescriptions = mappedLocations.map((location) => ({
					is_current: location.position.is_current,
				}));

				setLocation((prevLocation) => ({
					...prevLocation,
					proposedLocations: mappedLocations,
				}));

				setLocationDescription(mappedDescriptions);
			}
		}
	}, [dashboardData, screenLocation]);

	const handleOnDiscriptionChange = (name, value, index) => {
		setLocationDescription((prevDescription) => {
			const updatedDescription = [...prevDescription];

			const updatedItem = {
				...updatedDescription[index],
				[name]: value,
			};

			updatedDescription[index] = updatedItem;
			return updatedDescription;
		});
	};
	const handleRemoveLocation = (index) => {
		if (index > -1) {
			setLocationDescription((prevDescription) => {
				const newDescription = [...prevDescription];
				newDescription.splice(index, 1);
				return newDescription;
			});

			setLocation((prevLocation) => {
				if (prevLocation && prevLocation.proposedLocations) {
					const newProposedLocations = [...prevLocation.proposedLocations];
					newProposedLocations.splice(index, 1);
					return {
						...prevLocation,
						proposedLocations: newProposedLocations,
					};
				}
				return prevLocation;
			});

			setProposedLocationIndex((prevIndex) => prevIndex - 1);
		}
	};

	const addNewLocation = () => {
		setLocation((prev) => ({
			...prev,
			proposedLocations: [
				...prev.proposedLocations,
				{
					address: { freeformAddress: "" },
					position: { is_current: false, lat: null, lon: null },
				},
			],
		}));

		setLocationDescription((prev) => [
			...prev,
			{
				is_current: false,
				note: "",
				status: "",
				gross_price_sf: "",
				opex_value: "",
				leasing_company: "",
			},
		]);
		setProposedLocationIndex(proposedLocationIndex + 1);
	};

	const handleOnAddressChange = (value, name, index = 0) => {
		if (errorLocationValidation.find((obj) => obj.type === "proposedLocations")) {
			setErrorLocationValidation([]);
		}

		setLocation((prevLocation) => {
			if (name === "currentLocation") {
				return {
					...prevLocation,
					[name]: value,
				};
			} else if (name === "proposedLocations") {
				const newProposedLocations = [...prevLocation.proposedLocations];
				newProposedLocations[index] = value;
				return {
					...prevLocation,
					proposedLocations: newProposedLocations,
				};
			}
			return prevLocation;
		});
	};

	return (
		<>
			<div class='card-title'>
				<label style={{ marginRight: 0 }} for=''>
					Locations
				</label>
				<span class='sub-text'>You may add up to {maxAllowed} locations</span>
			</div>
			{error && (
				<span className='sub-text' style={{ color: "red" }}>
					*{errorMessage}
				</span>
			)}

			<div className='form-row show-all'>
				{location?.proposedLocations?.length > 0
					? location.proposedLocations.map((item, index) => (
							<div className='group-field' key={index}>
								{index > 0 && (
									<div
										style={{ width: "50px", height: "50px", top: "-26px" }}
										className='icon-remove d-block z-1'
										onClick={() => handleRemoveLocation(index)}
									>
										<Icon
											theme='primary'
											className='pointer svg-fill--primary border-0'
											size='lg'
											icon={"close-circle-line"}
										/>
									</div>
								)}
								<GeneralLocationFields
									handleOnAddressChange={handleOnAddressChange}
									handleFocus={handleFocus}
									handleBlur={handleBlur}
									location={location}
									index={index}
									handleOnDiscriptionChange={handleOnDiscriptionChange}
									locationDescription={locationDescription}
									maxLengthTextArea={maxLengthTextArea}
								/>

								<div>
									{!officeLocationType ? (
										<OfficeFields
											handleFocus={() => {}}
											handleBlur={() => {}}
											index={index}
											handleOnDiscriptionChange={handleOnDiscriptionChange}
											locationDescription={locationDescription}
										/>
									) : (
										<IndustrialFields
											handleFocus={() => {}}
											handleBlur={() => {}}
											index={index}
											handleOnDiscriptionChange={handleOnDiscriptionChange}
											locationDescription={locationDescription}
										/>
									)}
								</div>

								<UploadPDFsToLocations
									index={index}
									handleLocationPDFFiles={handleLocationPDFFiles}
									location={
										proposedLocations?.proposedLocations
											? proposedLocations?.proposedLocations[index]
											: {}
									}
									pdfFileName={pdfFileName?.filter((files) => files.location_index === index)}
									progressFileUpload={progressFileUpload}
									errorFileUpload={errorFileUpload}
									removePDFFile={removePDFFile}
									cancelPDFUpload={cancelPDFUpload}
								/>
								<div>
									{proposedLocations?.proposedLocations &&
										proposedLocations?.proposedLocations[index] &&
										proposedLocations?.proposedLocations[index].pdfs &&
										proposedLocations?.proposedLocations[index].pdfs.some(
											(pdf) => pdf.extracted_images?.length > 0,
										) && (
											<PdfImages
												index={index}
												locationId={proposedLocations?.proposedLocations[index].id}
												images={proposedLocations?.proposedLocations[index].pdfs.map((pdf) => ({
													location_id: pdf.location_id,
													pdf_name: pdf.name,
													extracted_images: pdf.extracted_images || [],
												}))}
												photos={photos}
												setPhotos={setPhotos}
											/>
										)}
								</div>
							</div>
					  ))
					: null}

				{location?.proposedLocations?.length < maxAllowed && (
					<div className='text-center add-more-filed-btn'>
						<button
							className='btn primary rounded'
							onClick={(e) => {
								e.preventDefault();

								addNewLocation();
							}}
						>
							Add New
						</button>
						<div className='upload-btn-wrapper'>
							<div className='upload-area'>
								<input
									type='file'
									name='file'
									id='file'
									onChange={handleOnChangeFile}
									onClick={(event) => {
										event.target.value = null;
									}}
								/>
								<b>Upload csv/xlsx</b>
							</div>
							<span className='fm_error'>
								{csvError.map((data, index) => (
									<React.Fragment key={index}>
										{data}
										<br />
									</React.Fragment>
								))}
							</span>
						</div>
					</div>
				)}
				<ExcelTemplate officeLocationType={officeLocationType} />
			</div>
		</>
	);
}

export default AddEditLocations;
