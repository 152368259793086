import React from "react";

import { checkValueIsPositiveDecimal } from "../../../utils/constants";
import SelectBox from "../../control-component/new-select-box.component";
import SelectWithSearchBox from "../../../pages/townson-dashboard-list/components/client-data-upload-component/select-with-search-box";
import Textarea from "../../control-component/text-area.component";
function GeneralLocationFields({
	handleBlur,
	handleFocus,
	maxLengthTextArea,
	location,
	locationDescription,
	handleOnDiscriptionChange,
	index,
	handleOnAddressChange,
}) {
	return (
		<>
			<SelectWithSearchBox
				selectedProposedLocation={location.proposedLocations}
				setSelectedItem={(e) => {
					handleOnAddressChange(e, "proposedLocations", index);
				}}
				key={index}
				index={index}
				lableName='Location'
			/>

			<div
				className='input-item'
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<input
					type='checkbox'
					name='is_current'
					checked={locationDescription[index] && locationDescription[index].is_current}
					onChange={(e) => {
						handleOnDiscriptionChange("is_current", e?.target?.checked, index);
					}}
					style={{ display: "inline" }}
				/>
				<label
					style={{
						display: "inline-block",
						position: "relative",
						top: "unset",
						left: "unset",
					}}
				>
					Is a Current Location?
				</label>
			</div>
			<div style={{ marginBottom: "10px" }}></div>
			<SelectBox
				selectItem={locationDescription[index] && locationDescription[index]?.status}
				setSelectedItem={(e) => {
					handleOnDiscriptionChange("status", e.name, index);
				}}
				lableName='Select Status'
				options={[
					{
						name: "Existing",
					},
					{
						name: "Under Construction",
					},
					{
						name: "Proposed",
					},
				]}
				searchable={false}
				id='name'
				name='name'
			/>
			<div className='input-item'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.gross_price_sf ? " hasFocus" : "")
					}
					type='text'
					name='gross_price_sf'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.gross_price_sf
							? locationDescription[index]?.gross_price_sf
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange(
							"gross_price_sf",
							checkValueIsPositiveDecimal(e?.target?.value),
							index,
						);
					}}
				/>
				<label className=''>Gross Price per square foot(Annually)</label>
			</div>
			<div className='input-item'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.opex_value ? " hasFocus" : "")
					}
					type='text'
					name='opex_value'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.opex_value
							? locationDescription[index]?.opex_value
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange("opex_value", checkValueIsPositiveDecimal(e?.target?.value), index);
					}}
				/>
				<label className=''>Operating Expenses (Annually)</label>
			</div>
			<div className='input-item'>
				<input
					className={
						"select-trigger-wrap" +
						(locationDescription[index] && locationDescription[index]?.leasing_company ? " hasFocus" : "")
					}
					type='text'
					name='leasing_company'
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={
						locationDescription[index] && locationDescription[index]?.leasing_company
							? locationDescription[index]?.leasing_company
							: ""
					}
					onChange={(e) => {
						handleOnDiscriptionChange("leasing_company", e?.target?.value, index);
					}}
				/>
				<label className=''>Leasing Company Name</label>
			</div>
			<div className='input-item-wrap'>
				<Textarea
					name='notes'
					lable='Notes'
					id={index}
					value={
						locationDescription[index] && locationDescription[index]?.notes
							? locationDescription[index]?.notes
							: ""
					}
					handleChange={(e) => {
						if (e?.target?.value.length <= maxLengthTextArea) {
							handleOnDiscriptionChange("notes", e?.target?.value, index);
						}
					}}
				/>
				<em>
					{locationDescription[index]?.note ? locationDescription[index]?.note.length : 0}/
					{maxLengthTextArea.toString()}
				</em>
			</div>
		</>
	);
}

export default GeneralLocationFields;
