import React, { createContext, useContext, useState, useEffect } from "react";
import { getComparisonMetrics, updateComparisonMetrics } from "../../services/property-comparison.service";

const ComparisonContext = createContext();

export function ComparisonProvider({ children, isAuthenticated }) {
	const [metricEdits, setMetricEdits] = useState({});

	const updateMetricValue = (locationId, metricKey, newValue) => {
		const cleanedValue = newValue.replace(/--/g, "");

		setMetricEdits((prev) => ({
			...prev,
			[locationId]: {
				...(prev[locationId] || {}),
				[metricKey]: cleanedValue,
			},
		}));
	};

	const saveMetricEdits = async () => {
		const response = await updateComparisonMetrics(metricEdits);

		if (response?.code === 200) {
			setComparisonData((prevData) => {
				const storedData = JSON.parse(localStorage.getItem("comparisonData"));
				const newLocations = storedData.locations.map((location) => {
					if (metricEdits[location._id]) {
						return {
							...location,
							...metricEdits[location._id],
						};
					}
					return location;
				});

				const updatedData = { ...storedData, locations: newLocations };
				localStorage.setItem("comparisonData", JSON.stringify(updatedData));
				return updatedData;
			});
			setMetricEdits({});
		}
	};

	const cancelMetricEdits = () => {
		setMetricEdits({});
	};

	const [comparisonData, setComparisonData] = useState({
		loaded: false,
		locations: [],
		pdfs: [],
		notes: "N/A",
		dashboard_title: "",
		selectedLocations: {},
		selectedMetrics: {},
	});

	const [apiMetrics, setApiMetrics] = useState({
		code: 0,
		data: null,
	});

	const updateLocationsWithMetrics = (locations, metricsData) => {
		return locations.map((location) => {
			const matchingMetrics = metricsData.find(
				(metrics) => metrics.location.location_name === location.location_name,
			);

			if (!matchingMetrics) return location;

			// Use MIT living wage for "1 Adult 0 Children"
			const mitWage =
				matchingMetrics.mit_living_wage_metrics.find((metric) => metric.label_name === "1 Adult 0 Children")
					?.label_value || "N/A";

			const avgSalary =
				matchingMetrics.labor_cost_metrics.find((metric) => metric.label_name === "Avg. Hourly Earnings")
					?.label_value || "N/A";

			// Find job count from latest year in occupationOverview
			const jobsMetrics = matchingMetrics.occupation_metrics.occupationOverview
				.filter((metric) => metric.label_name === "Jobs")
				.sort((a, b) => parseInt(b.year) - parseInt(a.year));
			const jobCount = jobsMetrics[0]?.label_value || "N/A";

			const locationQuotient =
				matchingMetrics.occupation_metrics.occupationOverview.find((metric) =>
					metric.label_name.includes("Location Quotient"),
				)?.label_value || "N/A";

			return {
				...location,
				col_apt: Number(matchingMetrics.monthly_housing.label_value.replace(/[^0-9]/g, "")).toLocaleString(),

				avg_wage: Math.round(avgSalary * 100) / 100,
				mit_wage: mitWage,
				job_count: Math.round(jobCount),
				location_quotient: Math.round(locationQuotient * 100) / 100,
			};
		});
	};

	useEffect(() => {
		const loadData = async () => {
			if (!isAuthenticated) return;

			try {
				const savedData = localStorage.getItem("comparisonData");
				if (savedData) {
					const parsedData = JSON.parse(savedData);
					setComparisonData(parsedData);
					if (parsedData.locations?.length) {
						const mappedLocations = parsedData.locations.map((location) => ({
							location_name: location.location_name,
							radius_size: 15,
							latitude: location.latitude,
							longitude: location.longitude,
							soc_name: parsedData.socData.soc_name,
							soc_code: parsedData.socData.soc_code,
						}));

						const metricsData = await getComparisonMetrics(mappedLocations);
						setApiMetrics({ code: metricsData.code, data: metricsData.data });
					}
				}
			} catch (error) {
				console.error("Error loading metrics:", error);
			}
		};

		loadData();
	}, []);

	const setComparisonLocations = async (dashboardData, selectedLocations, selectedMetrics, metrics, socData) => {
		try {
			const filteredLocations = dashboardData.proposedLocations.filter(
				(location) => selectedLocations[location._id],
			);

			const mappedLocations = filteredLocations.map((location) => ({
				location_name: location.location_name,
				radius_size: 15,
				latitude: location.latitude,
				longitude: location.longitude,
				soc_name: socData.soc_name,
				soc_code: socData.soc_code,
			}));

			const metricsData = await getComparisonMetrics(mappedLocations);
			setApiMetrics({ code: metricsData.code, data: metricsData.data });

			const baseLocations = filteredLocations.map((location) => ({
				_id: location._id,
				soc_name: socData.soc_name,
				percent_leased: location.percent_leased,
				soc_code: socData.soc_code,
				is_current: location.is_current,
				latitude: location.latitude,
				longitude: location.longitude,
				location_name: location.location_name,
				status: location.status,
				building_sf: location.building_sf,
				year_built: location.year_built,
				electric_price_sf: location.electric_price_sf,
				available_sf: location.sf ?? location.available_sf,
				gross_price_sf: location.sf ?? location.gross_price_sf,
				office_sf: location.office_sf,
				clear_height: location.clear_height,
				car_trailer_parking: location.car_trailer_parking,
				parking_ratio: location.parking_ratio,
				docs_grade_level_doors: location.docs_grade_level_doors,
				base_rent: location.base_rent,
				opex: location.opex_value,
				leasing_company: location.leasing_company,
				location_suite: location.location_suite,
				employee_proposed_location_drivetime_average: location.employee_proposed_location_drivetime_average,
				customer_proposed_location_drivetime_average: location.customer_proposed_location_drivetime_average,
				notes: location.notes ?? "",
			}));

			const updatedLocations =
				metricsData.code === 200 && metricsData.data
					? updateLocationsWithMetrics(baseLocations, metricsData.data)
					: baseLocations;

			const filteredMetrics = metrics.filter((metric) => selectedMetrics[metric[Object.keys(metric)[0]]]);

			const data = {
				locations: updatedLocations,
				metrics: filteredMetrics,
				dashboard_property_type: dashboardData.is_industrial_location_type ? "Industrial" : "Office",
				dashboard_title: dashboardData.dashboard_title,
				selectedLocations,
				selectedMetrics,
				socData: {
					soc_code: socData.soc_code,
					soc_name: socData.soc_name,
				},
				loaded: true,
				pdfs: comparisonData.pdfs || [],
				notes: comparisonData.notes || "N/A",
			};

			setComparisonData(data);

			localStorage.setItem("comparisonData", JSON.stringify(data));
			return data;
		} catch (error) {
			console.error("Error in setComparisonLocations:", error);

			setComparisonData((prevData) => ({
				...prevData,
				error: error.message,
				loaded: false,
			}));
		}
	};

	const clearComparisonData = () => {
		setComparisonData({
			locations: [],
			pdfs: [],
			notes: "N/A",
			metrics: [],
			dashboard_title: "",
			selectedLocations: {},
			selectedMetrics: {},
			loaded: false,
		});
		localStorage.removeItem("comparisonData");
	};

	return (
		<ComparisonContext.Provider
			value={{
				comparisonData,
				setComparisonData,
				setComparisonLocations,
				clearComparisonData,
				setApiMetrics,
				apiMetrics,
				metricEdits,
				updateMetricValue,
				saveMetricEdits,
				cancelMetricEdits,
			}}
		>
			{children}
		</ComparisonContext.Provider>
	);
}

export function useComparison() {
	const context = useContext(ComparisonContext);
	if (!context) {
		throw new Error("useComparison must be used within a ComparisonProvider");
	}
	return context;
}
